import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import Button from '../button/button';
import ChoosePerformance from '../choose-performance/choose-performance';
import EventDate from '../event-date/event-date';
import EventMeta from '../event-meta/event-meta';
import Sidebar from '../sidebar/sidebar';
import WYSIWYG from '../wysiwyg/wysiwyg';
import VenueEventList from '../venue-event-list.js/venue-event-list';

import styles from './content-display.module.scss';

import { checkForInternalTicketURL } from '../../helpers/menu-helpers';

import Icon from '../icons/icon';

const ContentDisplay = ({
  title,
  longTitle,
  body,
  cost,
  date,
  html,
  imageLocation = 'sidebar',
  relationships,
  sidebarContent,
  subscription,
  tickets,
  field_image,
  feedMeta,
  fullWidthLayout,
  children,
  fields,
  spektrixClientName,
  upcomingEvents
}) => {
  let image = '';
  if (
    relationships &&
    relationships.field_image &&
    relationships.field_image.max_1600_10_7 &&
    relationships.field_image.max_1600_16_9
  ) {
    image = {
      resolutions:
        relationships.field_image.max_1600_10_7.childImageSharp.resolutions,
      sizes: relationships.field_image.max_1600_16_9.childImageSharp.sizes
    };
  }

  // Account for Spektrix imported events.
  const spektrix = fields && fields.spectrix;
  const spektrixId = feedMeta && (feedMeta.guid || feedMeta[0].guid);
  const isMultiple = fields && fields.spectrix && fields.spectrix.numberInstances;

  // If there's a body summary we need some <p> tags around it for spacing.
  const summary = body.summary && `<p>${body.summary}</p>`;

  const distance = relationships && relationships.field_distance && relationships.field_distance.metatag[0].attributes.content;
  const experience = relationships && relationships.field_experience && relationships.field_experience.metatag[0].attributes.content;
  const cuisine = relationships && relationships.field_cuisine && relationships.field_cuisine.map(cuisine => cuisine.metatag[0].attributes.content).join(', ');
  
  return (
    <React.Fragment>
      {image && (
        <Img
          className={styles.topImg}
          sizes={image.sizes}
          // These images only show on mobile
          style={{ display: 'none' }}
          alt={field_image && field_image.alt}
        />
      )}
      <article className={styles.article} aria-label={title}>
        <div className={fullWidthLayout ? `${styles.contentWrapper} ${styles.contentWrapperFull}` : styles.contentWrapper }>
          <h1 className={styles.title}>{longTitle || title}</h1>
          {date && <EventDate date={date} />}
          <EventMeta
              className={styles.meta}
              venue={
                relationships?.venue 
                  ? relationships.venue.name || relationships.venue.title 
                  : null
              }
              venueLink={relationships?.venue?.path?.alias || null}
          />

          <WYSIWYG>
            {/* Some content will only have a summary. If there's no body fall back to summary. */}
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{ __html: body.processed || summary || body }}
            />           
          </WYSIWYG>
          {(distance || experience || cuisine) && (
            <div className={styles.additionalInfo}>
              {distance && (
                <p>
                  <strong>Distance:</strong> {distance}
                </p>
              )}
              {experience && (
                <p>
                  <strong>Experience:</strong> {experience}
                </p>
              )}
              {cuisine && (
                <p>
                  <strong>Cuisine:</strong> {cuisine}
                </p>
              )}
            </div>
          )}
          
          {relationships && (relationships.venue || relationships.series) && (
            <EventMeta
              className={styles.meta}              
              series={relationships.series && relationships.series.name}              
              cost={cost}
            />
          )}

          {/*
            If this is a Spektrix event AND there are multiple performances,
            render the choose performance component.
            TODO: account for single events.
           */}
          {(spektrixId) && (
            <ChoosePerformance
              spektrixClientName={spektrixClientName}
              spektrix={spektrix}
              tickets={tickets}
              instanceID={spektrixId}
              subscription={subscription}
              eventName={title}
            />
          )}
          {/*
            TODO: Prob need a better alternative to isSpektrix as
            we still need to support single Spektrix instances as well as multiple.
           */}
          {!isMultiple && (tickets || subscription) && (
            <Buttons tickets={tickets} subscription={subscription} />
          )}
          {imageLocation === 'below' && image && (
            <Img
              className={styles.bottomImg}
              sizes={image.sizes}
              // These images only show on mobile
              style={{ display: 'none' }}
              alt={field_image && field_image.alt}
            />
          )}
          {html && (
            <div
              className={styles.iframeContainer}
              dangerouslySetInnerHTML={{ __html: html.value }}
            />
          )}
          {children}
          {upcomingEvents && upcomingEvents.length > 0 && (
            <div>
            <VenueEventList events={upcomingEvents} venue={title}/>
            </div>
          )}
          
        </div>
        {(imageLocation === 'sidebar' || sidebarContent) && (
          <Sidebar
            image={imageLocation === 'sidebar' ? image.resolutions : null}
            imageAlt={
              imageLocation === 'sidebar' &&
              field_image &&
              field_image.alt
            }
            content={sidebarContent}
          />
        )}
      </article>
    </React.Fragment>
  );
};

ContentDisplay.propTypes = {
  body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  cost: PropTypes.string,
  date: PropTypes.object,
  html: PropTypes.object,
  imageLocation: PropTypes.oneOf(['sidebar', 'below', 'none']),
  longTitle: PropTypes.string,
  relationships: PropTypes.object,
  series: PropTypes.string,
  sidebarContent: PropTypes.node,
  subscription: PropTypes.object,
  tickets: PropTypes.object,
  title: PropTypes.string.isRequired,
  venue: PropTypes.string,
  feedMeta: PropTypes.object,
  fullWidthLayout: PropTypes.bool,
  fields: PropTypes.object,
  spektrixClientName: PropTypes.string,
};

export default ContentDisplay;

// Support custom text for buttons with a fallback.
const Buttons = ({ tickets, subscription }) => (
  <div className={styles.buttons}>
    {tickets ? (
      checkForInternalTicketURL(tickets.uri) ? (
        <Button
          className={styles.ticketButton}
          to={tickets.uri}
          textStyle="upper"
          aria-label={`Visit ${tickets.title || 'tickets page'}`}
        >
          {tickets.title || 'Find Tickets'}
        </Button>
      ) : (
        <div className={styles.externalLinkWrapper}>
          <Button
            to={tickets.uri}
            aria-label={`Visit ${tickets.title || 'tickets page'}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.externalLink}
          >
            {tickets.title || 'Find Tickets'} <span className={styles.externalIcon}>
              <Icon
                icon="newTab"
                aria-hidden="true"
                className={styles.externalIcon}
                width="18"
                height="18"
              />
            </span>
          </Button>
        </div>
      )
    ) : null}

    {subscription && (
      <Button
        className={styles.subButton}
        to={subscription && subscription.uri}
        textStyle="upper"
        color="blueMedium"
      >
        {subscription && subscription.title || 'Subscription Packages'}
      </Button>
    )}
  </div>
);

Buttons.propTypes = {
  subscription: PropTypes.object,
  tickets: PropTypes.object,
};
