import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Link from '../../components/link/link';

import styles from './venue-event-list.module.scss';

const VenueEventList = ({ events = [], venue }) => {

  const upcomingEvents = events.filter((event) => {
    if (event.field_date && event.field_date.end_value) {
      const eventEndDate = moment(event.field_date.end_value, 'MMMM D, YYYY');
      const today = moment();
      // include events that are either ongoing or start in the future
      return eventEndDate.isSameOrAfter(today, 'day'); // event hasn't ended yet
    }
    if (event.field_date && event.field_date.value) {
      const eventStartDate = moment(event.field_date.value, 'MMMM D, YYYY');
      const today = moment();
      return eventStartDate.isSameOrAfter(today, 'day'); // Event starts in the future
    }
    return false;
  });

  if (upcomingEvents.length > 0) {
    return (
      <div className={styles.eventListContainer}>
        <h2>Upcoming Events at {venue}</h2>
        {upcomingEvents.map((event) => (
          <Link key={event.title} to={event.path.alias} aria-label={`View details about the event: ${event.title}`} className={styles.eventLink}>
            <div className={styles.eventWrapper}>
              <img src={event.field_image.imageDerivatives.links.max_1600_16_9.href}  alt={`${event.title}`} loading="lazy" />
              <div className={styles.contentWrapper}>
                <h3>{event.title}</h3>
                {event.field_date.value === event.field_date.end_value ? (
                  <p aria-label={`Event date: ${event.field_date.value}`}>{event.field_date.value} </p>
                ) : (
                  <p aria-label={`Event date: ${event.field_date.value}`}>{event.field_date.value} - {event.field_date.end_value}</p>
                )}
              </div>

            </div>
          </Link>
        ))}
      </div>
    );
  }
  else {
    return null;
  }
};

VenueEventList.propTypes = {
  events: PropTypes.array,
  venue: PropTypes.string,
};

export default VenueEventList;
