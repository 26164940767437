import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';

import Button from '../button/button';
import Icon from '../icons/icon';

import styles from './choose-performance.module.scss';

const ChoosePerformance = ({
  instanceID,
  tickets,
  spektrix,
  spektrixClientName,
  subscription,
}) => {

  const [performances, setPerformances] = useState({
    error: null,
    events: null,
    loading: false
  });

  const getPerformances = () => {
    setPerformances({
      loading: true
    });

    axios.get(`/${spektrixClientName}/api/v3/events/${instanceID}/instances`)
      .then(function (response) {
        const events = response.data;
        const error = events.length === 0 ? 'We\'re sorry, no tickets are available at this time.' : null;

        // Add the event instances to state.
        setPerformances({
          events: events,
          loading: false,
          error
        });
      })
      .catch(function () {
        // Deal with API errors.
        setPerformances({
          error: 'We\'re sorry, no tickets are available at this time.',
          loading: false
        });
      });
  };

  const hasMultiple = spektrix.numberInstances > 1;
  const isCanceled = spektrix.cancelled;

  return (
    <React.Fragment>
      {/*
        If there are multiple events, show the button that loads all events.
      */}
      {/* 2025 update multiple events will be directed the URL provided for the event no need for multiple buttons */}
      {!performances.events && hasMultiple && (<Button
        className={isCanceled ? styles.cancelled : styles.ticketButton}
        textStyle="upper"
        to={ !isCanceled && tickets.uri }
        disabled={isCanceled}
      >
        {tickets && tickets.title  ? tickets.title : 'Find Tickets'}
        {performances.loading && (
          <Icon
            icon="loader"
            height="20"
            width="20"
            className={styles.loader}
          />
        )}
      </Button>)}
      {/* {!performances.events && hasMultiple && (<Button
        className={styles.ticketButton}
        textStyle="upper"
        onClick={getPerformances}
        disabled={performances.loading}
      >
        {tickets && tickets.title || 'Find Tickets'}
        {performances.loading && (
          <Icon
            icon="loader"
            height="20"
            width="20"
            className={styles.loader}
          />
        )}
      </Button>)} */}
      {/*
        If multiple events are fetched, display them.
      */}
      {/* {performances.events && (
        <div className={styles.performances}>
          {performances.events.map(item => (
            <div key={`performance-${item.id}`} className={styles.performance}>
              <time dateTime={item.start}>
                <strong>{moment(item.start).format('MMM Do YYYY')}</strong>
                <span className={styles.time}>{moment(item.start).format('h:mm a')}</span>
              </time>
              <Button
                className={item.cancelled && styles.cancelled}
                // The ID we need for the event is only the numbers from
                // the id and not the entire string.
                to={ !item.cancelled && `choose-seats?performance=${
                  item.id.
                    match(/[0-9]/gi)
                    .join('')
                }` }
                disabled={item.cancelled}>
                { item.cancelled ? 'Cancelled' : 'Find Tickets' }
              </Button>
            </div>
          ))}
        </div>
      )} */}
      {/*
        If there is only one event, create a link to that event.
      */}
      {!hasMultiple && (<Button
        className={isCanceled ? styles.cancelled : styles.ticketButton}
        textStyle="upper"
        to={ !isCanceled && tickets.uri }
        disabled={isCanceled}
      >
        {tickets && tickets.title ? tickets.title : 'Find Tickets'}
        {performances.loading && (
          <Icon
            icon="loader"
            height="20"
            width="20"
            className={styles.loader}
          />
        )}
      </Button>)}
      {/*
        If there is an error display it.
      */}
      {performances.error && (
        <div className={styles.performances}>
          <div className={styles.performance}>
            { performances.error }
          </div>
        </div>
      )}
      {/*
        Call-to-action Link #2 (in Drupal) is mapped to the subscription variable.
        If this event has a CTA #2 link defined, display it as well.
      */}
      {subscription?.uri && subscription?.title && (<Button
        className={styles.ticketButton}
        textStyle="upper"
        to={subscription.uri}
        color="blueMedium"
      >
        {subscription.title}
      </Button>)}
    </React.Fragment>
  );
};

ChoosePerformance.propTypes = {
  instanceID: PropTypes.string,
  tickets: PropTypes.object,
  spektrix: PropTypes.object,
  spektrixClientName: PropTypes.string,
};

export default ChoosePerformance;
